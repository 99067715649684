import {BayPIN, Pin} from "../../models/bays";

export const PINUPDATING = "PINUPDATING";
export const PINUPDATED = "PINUPDATED";
export const PINLOADING = "PINLOADING";
export const PINLOADINGDONE = "PINLOADINGDONE";
export const PINLOADED = "PINLOADED";
export const PINCREATING = "PINCREATING";
export const PINCREATED = "PINCREATED";
export const PINCOMPLETED = "PINCOMPLETED";
export const PINERROR = "PINERROR";
export const PINERRORHANDLED = "PINERRORHANDLED";
export const PIN_UPCOMING = "PIN_UPCOMING";
export const PIN_UPCOMING_HANDLED = "PIN_UPCOMING_HANDLED";

// export interface BayPinState {
//   bayId: string;
//   isLoading: boolean;
//   pin: BayPIN;
// }

export interface PinsState {
  isLoading: boolean;
  bayPins: BayPIN[];
  errorObject?: any;
  upcoming?: Pin;
}
