import React, { useState, useEffect } from "react";
import {
  TextField,
  Dialog,
  DialogContent,
  makeStyles,
  Theme,
  createStyles,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  FormControl,
  FormGroup,
  Button,
  MuiThemeProvider,
  Input,
  InputLabel,
  useTheme,
  Grid,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import MaskedInput from "react-text-mask";
import Select from "@material-ui/core/Select";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import DateFnsUtils from "@date-io/date-fns";
import { v1 as uuid } from "uuid";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { createUser } from "../../redux/actions/users.actions";
import { UserSignupModel } from "../../models/users";
import { RootState } from "../../redux/store";

interface UserCreatorProps {
  show: boolean;
  handleClose: () => void;
}

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    root: {
      background: "white",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    notime: {
      color: "red",
    },
    time: {
      color: "primary",
    },
    rootStyle: {
      borderRadius: 25,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
);

export const UserCreator: React.FC<UserCreatorProps> = ({
  show,
  handleClose,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const userRole = useSelector<RootState, string>(
    (selector) => selector.facility.role
  );

  const handleCreate = () => {
    var createdUser: UserSignupModel = {
      email: emailAddress,
      password: password,
      firstName: firstName,
      lastName: lastName,
    };
    if (userRole == "ADMIN" || userRole == "MANAGER")
      dispatch(createUser(createdUser));
    setFirstName("");
    setLastName("");
    setEmailAddress("");
    setPassword("");
    handleClose();
  };

  const handleFirstNameChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setFirstName(event.target.value as string);
  };

  const handleLastNameChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setLastName(event.target.value as string);
  };

  const handleEmailAddressChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setEmailAddress(event.target.value as string);
  };

  const handlePasswordChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setPassword(event.target.value as string);
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Dialog
        // fullScreen
        classes={{
          paper: classes.rootStyle,
        }}
        open={show}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              User Creation
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <React.Fragment>
            <div className="TextField-with-border-radius">
              {
                <div>
                  <Grid>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <TextField
                        id="firstName"
                        label="First Name"
                        type="text"
                        color="primary"
                        value={firstName}
                        onChange={handleFirstNameChange}
                        variant="outlined"
                      />
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <TextField
                        id="lastName"
                        label="Last Name"
                        type="text"
                        color="primary"
                        value={lastName}
                        onChange={handleLastNameChange}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                  <Grid container>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <TextField
                        margin="normal"
                        id="emailAddress"
                        label="Email Address"
                        type="text"
                        color="primary"
                        value={emailAddress}
                        onChange={handleEmailAddressChange}
                        variant="outlined"
                        fullWidth
                      />
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <TextField
                        margin="normal"
                        id="password"
                        label="Password"
                        type="text"
                        color="primary"
                        value={password}
                        onChange={handlePasswordChange}
                        variant="outlined"
                        fullWidth
                      />
                    </FormControl>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCreate}
                    >
                      Create User
                    </Button>
                  </Grid>
                </div>
              }
            </div>
          </React.Fragment>
        </DialogContent>
      </Dialog>
    </MuiThemeProvider>
  );
};

export default UserCreator;
