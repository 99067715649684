import React, { useEffect } from "react";
import {
  Button,
  useTheme,
  MuiThemeProvider,
  makeStyles,
  Theme,
  createStyles,
  fade,
  Typography,
  CircularProgress,
  Grid,
  Divider,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import GetAppIcon from "@material-ui/icons/GetApp";
import axios from "axios";
import { v1 as uuid } from "uuid";
import moment from "moment";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import { useSelector, useDispatch } from "react-redux";
import { UserModel } from "../models/users";
import { Facility } from "../models/facility";
import { RootState } from "../redux/store";
import { UserListItem } from "../components/users/UserListItem";
import { UserCreator } from "../components/users/UserCreator";
import FacilityInfo from "../components/FacilityInfo";
import { getFacilityUsers } from "../redux/actions/users.actions";
import ScaleIn from "material-ui/internal/ScaleIn";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    table: {
      minWidth: 650,
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    addIcon: {
      height: "10%",
      width: "10%",
      padding: theme.spacing(0, 2),
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
  })
);

export const Users: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedFacility = useSelector<RootState, Facility>(
    (selector) => selector.facility.selectedFacility
  );
  const [userData, setUserData] = React.useState<UserModel[] | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [showData, setShowData] = React.useState(false);
  const [showUserCreator, setShowUserCreator] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [currentInformationLabel, setCurrentInformationLabel] = React.useState(
    ""
  );

  const getAllUsersForFacility = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/Users/${selectedFacility.uuid}`)
      .then((result) => {
        setUserData(result.data as UserModel[]);
      });
    setCurrentInformationLabel("All Users");
    setLoading(false);
  };

  const searchUsers = () => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/Users/${selectedFacility.uuid}/${searchText}`
      )
      .then((result) => {
        setUserData(result.data as UserModel[]);
      });
    setCurrentInformationLabel("User Search Results");
    setLoading(false);
  };

  const getUnauthorizedUsers = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/Users/Unregistered`)
      .then((result) => {
        setUserData(result.data as UserModel[]);
      });
    setCurrentInformationLabel("Unregistered Users");
    setLoading(false);
  };

  const handleCloseCreator = () => setShowUserCreator(false);
  const handleShowCreator = () => setShowUserCreator(true);

  useEffect(() => {
    getAllUsersForFacility();
  }, []);

  useEffect(() => {
    if (userData && userData.length > 0) setShowData(true);
    else setShowData(false);
  }, [userData]);

  const handleKeyPress = (e: any) => {
    if (e.keyCode === 13 || e.which === 13) {
      searchUsers();
    }
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Grid container alignItems="center">
        <FacilityInfo />
        <Grid xs={12} lg={6} item>
          <Grid container justify="flex-end" alignItems="center">
            <Typography variant="h3">Users</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Divider variant="middle" />
      <Button variant="contained" color="primary">
        <AddCircleIcon onClick={handleShowCreator}></AddCircleIcon>
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={getAllUsersForFacility}
      >
        Show All Users
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={getUnauthorizedUsers}
      >
        Show Unauthorized Users
      </Button>
      <UserCreator show={showUserCreator} handleClose={handleCloseCreator} />
      <hr />
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Search…"
          onChange={(e) => setSearchText(e.target.value)}
          onKeyPress={(e) => handleKeyPress(e)}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ "aria-label": "search" }}
        />
      </div>

      {loading && (
        <div className="loading">
          <Typography>Loading Data...</Typography>
          <CircularProgress />
        </div>
      )}
      {showData && userData !== null && (
        <Paper elevation={3}>
          <Grid xs={12} lg={6} item>
            <Grid container justify="flex-end" alignItems="center">
              <Typography variant="h4">{currentInformationLabel}</Typography>
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="right">First Name</TableCell>
                  <TableCell align="right">Last Name</TableCell>
                  <TableCell align="right">Phone Number</TableCell>
                  <TableCell align="right">Email Address</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userData.map((user) => {
                  return <UserListItem key={user.id} user={user} />;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </MuiThemeProvider>
  );
};

export default Users;
