import { applyMiddleware, combineReducers, createStore, Store } from "redux";
import thunk from "redux-thunk";
import authReducer from "./reducers/auth.reducer";
import baysReducer from "./reducers/bays.reducer";
import pinsReducer from "./reducers/pins.reducer";
import facilityReducer from "./reducers/facility.reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  bays: baysReducer,
  pins: pinsReducer,
  facility: facilityReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default function configureStore() {
  const store: Store<RootState> = createStore(
    rootReducer,
    applyMiddleware(thunk)
  );

  return store;
}
