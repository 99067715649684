import { SettingsModel } from "../../models/settings";
import React, { useState } from "react";
import {
  TextField,
  makeStyles,
  Theme,
  createStyles,
  FormControl,
  useTheme,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { updateSetting } from "../../redux/actions/settings.actions";

interface SettingListItemProps {
  setting: SettingsModel;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    root: {
      background: "white",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    notime: {
      color: "red",
    },
    time: {
      color: "primary",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
);

export const SettingListItem: React.FC<SettingListItemProps> = ({
  setting,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [value, setValue] = useState(setting.value);

  const loading = useSelector<RootState, boolean>(
    (selector) => selector.pins.isLoading
  );

  const handleValueChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setValue(event.target.value as string);
    dispatch(updateSetting(setting.id, event.target.value as string));
  };

  return (
    <React.Fragment>
      {
        <TableRow key={setting.id}>
          <TableCell align="right">{setting.name}</TableCell>
          <TableCell align="right">{setting.description}</TableCell>
          <TableCell align="right">
            <FormControl variant="outlined" className={classes.formControl}>
              <TextField
                id="value"
                type="text"
                color="primary"
                value={setting.value}
                onChange={handleValueChange}
                variant="outlined"
              />
            </FormControl>
          </TableCell>
          <TableCell align="right">{setting.type}</TableCell>
        </TableRow>
      }
    </React.Fragment>
  );
};

export default SettingListItem;
