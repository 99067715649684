import { SettingsModel } from "../../models/settings";

export const SETTINGUPDATING = "SETTINGUPDATING";
export const SETTINGUPDATED = "SETTINGUPDATED";
export const SETTINGLOADING = "SETTINGLOADING";
export const SETTINGLOADED = "SETTINGLOADED";
export const SETTINGCREATING = "SETTINGCREATING";
export const SETTINGCREATED = "SETTINGCREATED";

export interface SettingsState {
  isLoading: boolean;
  settings: SettingsModel[];
}
