import { UserModel } from "../../models/users";
import React, { useState, useEffect } from "react";
import {
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  makeStyles,
  Theme,
  createStyles,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  Button,
  Snackbar,
  CircularProgress,
  MuiThemeProvider,
  useTheme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import MaskedInput from "react-text-mask";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { BayPIN, BayDbModel, BayStatus } from "../../models/bays";
import moment from "moment";
import axios from "axios";
import { Alert, AlertTitle } from "@material-ui/lab";
import { changeBayState } from "../../redux/actions/bays.actions";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import { updateUser } from "../../redux/actions/users.actions";
import Checkbox from "@material-ui/core/Checkbox";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Facility } from "../../models/facility";

interface UserDetailProps {
  user: UserModel;
  show: boolean;
  handleClose: () => void;
}

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}
function TextMaskCustom(props: TextMaskCustomProps) {
  const { inputRef, ...other } = props;
  const selectedFacility = useSelector<RootState, Facility>(
    (selector) => selector.facility.selectedFacility
  );
  var phoneFormat = Array.from(selectedFacility.phoneNumberFormat.split(""));
  var phoneMask = new Array();
  phoneFormat.forEach((element: string) => {
    if (element == "_") {
      phoneMask.push(/\d/);
    } else {
      phoneMask.push(element);
    }
  });
  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={phoneMask}
      placeholderChar={"\u005F"}
      showMask
    />
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    root: {
      background: "white",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    notime: {
      color: "red",
    },
    time: {
      color: "primary",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
);

export const UserDetails: React.FC<UserDetailProps> = ({
  user,
  show,
  handleClose,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const originalUserInfo = user;
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [username, setUsername] = useState(user.userName);
  const [emailAddress, setEmailAddress] = useState(user.email);
  const [emailConfirmed, setEmailConfirmed] = useState(user.emailConfirmed);
  const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber);
  const [phoneNumberConfirmed, setPhoneNumberConfirmed] = useState(
    user.phoneNumberConfirmed
  );
  const [twoFactorEnabled, setTwoFactorEnabled] = useState(
    user.twoFactorEnabled
  );

  const handleSave = () => {
    var changedUser: UserModel = user;
    changedUser.firstName = firstName;
    changedUser.lastName = lastName;
    changedUser.userName = username;
    changedUser.email = emailAddress;
    changedUser.emailConfirmed = emailConfirmed;
    changedUser.phoneNumber = phoneNumber;
    changedUser.phoneNumberConfirmed = phoneNumberConfirmed;
    changedUser.twoFactorEnabled = twoFactorEnabled;
    dispatch(updateUser(changedUser));
    handleClose();
  };

  const handleReset = () => {
    setFirstName(originalUserInfo.firstName);
    setLastName(originalUserInfo.lastName);
    setUsername(originalUserInfo.userName);
    setEmailAddress(originalUserInfo.email);
    setEmailConfirmed(originalUserInfo.emailConfirmed);
    setPhoneNumber(originalUserInfo.phoneNumber);
    setPhoneNumberConfirmed(originalUserInfo.phoneNumberConfirmed);
    setTwoFactorEnabled(originalUserInfo.twoFactorEnabled);
  };

  const handleFirstNameChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setFirstName(event.target.value as string);
  };

  const handleLastNameChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setLastName(event.target.value as string);
  };

  const handleUsernameChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setUsername(event.target.value as string);
  };

  const handleEmailAddressChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setEmailAddress(event.target.value as string);
  };

  const handleEmailConfirmedChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEmailConfirmed(event.target.checked);
  };

  const handlePhoneNumberChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setPhoneNumber(event.target.value as string);
  };

  const handlePhoneNumberConfirmedChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setPhoneNumberConfirmed(event.target.value as boolean);
  };

  const handleTwoFactorEnabledChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setTwoFactorEnabled(event.target.value as boolean);
  };

  const loading = useSelector<RootState, boolean>(
    (selector) => selector.pins.isLoading
  );

  return (
    <MuiThemeProvider theme={theme}>
      <Dialog
        // fullScreen
        open={show}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {user.firstName} {user.lastName}'s Information
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <React.Fragment>
            {loading && (
              <div className="loading">
                <Typography color="primary">Loading User Details...</Typography>
                <CircularProgress color="primary" />
              </div>
            )}
            {!loading && (
              <div>
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    id="firstName"
                    label="First Name"
                    type="text"
                    color="primary"
                    value={firstName}
                    onChange={handleFirstNameChange}
                    variant="outlined"
                  />
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    id="lastName"
                    label="Last Name"
                    type="text"
                    color="primary"
                    value={lastName}
                    onChange={handleLastNameChange}
                    variant="outlined"
                  />
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    id="username"
                    label="Username"
                    type="text"
                    color="primary"
                    value={username}
                    onChange={handleUsernameChange}
                    variant="outlined"
                  />
                </FormControl>
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    id="emailAddress"
                    label="Email Address"
                    type="text"
                    color="primary"
                    value={emailAddress}
                    onChange={handleEmailAddressChange}
                    variant="outlined"
                  />
                </FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="emailConfirmed"
                      color="primary"
                      checked={emailConfirmed}
                      onChange={handleEmailConfirmedChange}
                      name="emailConfirmed"
                    />
                  }
                  label="Email Confirmed"
                />
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel>Phone Number</InputLabel>
                  <Input
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    id="phoneNumber"
                    inputComponent={TextMaskCustom as any}
                  />
                </FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="phoneNumberConfirmed"
                      color="primary"
                      checked={phoneNumberConfirmed}
                      onChange={handlePhoneNumberConfirmedChange}
                      name="phoneNumberConfirmed"
                    />
                  }
                  label="Phone Number Confirmed"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      id="TwoFactorEnabled"
                      color="primary"
                      checked={twoFactorEnabled}
                      onChange={handleTwoFactorEnabledChange}
                      name="twoFactorEnabled"
                    />
                  }
                  label="Two Factor Enabled"
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                >
                  Save Changes
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleReset}
                >
                  Reset Values
                </Button>
              </div>
            )}
          </React.Fragment>
        </DialogContent>
      </Dialog>
    </MuiThemeProvider>
  );
};

export default UserDetails;
