
export type BayModel = {
  id: number;
  uuid: string;
  name: string;
  pin: string;
  state: string;
};

export type BayPIN = {
  id: string;
  facilityId: string;
  bayId: string;
  bayNumber: number | null;
  pin: string;
  playerId: string;
  complete: boolean;
  minutes: number;
  startTime: Date;
  completeTime: Date;
  playMode: string;
  timeSlotStart: Date;
  timeSlotEnd: Date;
  reuse: boolean;
  hasTimeLeft: boolean;
  isStatic: boolean;
  isGroupPin: boolean;
  description: string;
  welcomeMessage: string;
};

export type BayDbModel = {
  id: number;
  uuid: string;
  facility_id: string;
  bayName: string;
  bayNumber: number;
  bayStatus: BayStatus;
  bay_equipment_name: string;
  bay_size_name: string;
  bay_size_id: number;
  bay_equipment_id: number;
  bayFloor: number;
  created_At: Date;
  updated_At: Date;
  created_By: number;
  updated_By: number;
  ssAda: boolean;
  protracer: boolean;
  leftHand_Sensor: boolean;
  rightHand_Sensor: boolean;
  bay_Suite_Id: number;
  seconds_Left: number;
  timeRemaining: Date;
  last_inactive_shot: Date;
  notes: string;
  inactive_shots: number;
  use_external_payment: boolean;
  static_pin: string;
  pin: string;
  pinId: string;
  minutes: number;
  start_time: Date;
};

export type BayUsageSlice = {
  timestamp: Date;
  idleBays: number;
  inUseBays: number;
  timestampFormatted: string;
};

export enum BayStatus {
  AVAILABLE = 1,
  RESERVED = 2,
  INUSE = 3,
  SERVICE = 4,
  BUSSING = 5,
  TECHNICAL = 6,
  NONASSIGNABLE = 7,
  WAITER = 8,
  NEWASSIGNMENT = 9,
  OFFLINE = 10,
  INUSE_ANDROID = 11,
  INUSE_IOS = 12,
  INUSE_SP_IN_BAY = 13,
  INUSE_MP_IN_BAY = 14
}

export type Pin = {
  id: string
  facilityId: string
  bayId: string
  bayNumber: number
  bayNumbers: number[] | undefined
  bayName: string | undefined
  pin: string
  playerId: string
  complete: boolean
  minutes: number
  startTime: Date
  completeTime: Date
  playMode: string
  timeSlotStart: Date
  timeSlotEnd: Date
  pricePaid: Number
  currency: Number
  reuse: boolean
  isStatic: boolean
  isGroupPin: boolean
  description: string
  welcomeMessage: string
  manualComplete: boolean
  playerName: string
  playerLastName: string
  playerEmail: string
  hasTimeSlot: boolean
  isWithinTimeSlot: boolean
  hasTimeLeft: boolean
  secondsLeft: number
  minutesLeft: number
  isMp: boolean
  isValid: boolean
  isActive: boolean
}

function getIsWithinTimeSlot(pinLeadTimeMinutes: number, hasTimeSlot: boolean,
                          timeSlotStart: Date | undefined, timeSlotEnd: Date | undefined) {

  if (hasTimeSlot && timeSlotStart !== undefined && timeSlotEnd !== undefined) {
    const now = new Date();

    if ((now.getTime() + (pinLeadTimeMinutes * 60 * 1000) >= timeSlotStart!.getTime())
        && now.getTime() < timeSlotEnd!.getTime()) {
      return true;
    }

    return false;
  }

  return true;
}

export function getBayMinutesLeft(pinBehavior: string, pinLeadTimeMinutes: number, bay: BayDbModel) {
  let timeSlotStart = bay.timeRemaining === null ? undefined : new Date(bay.timeRemaining);
  let timeSlotEnd = bay.last_inactive_shot === null ? undefined : new Date(bay.last_inactive_shot);
  let startTime = bay.start_time === null ? undefined : new Date(bay.start_time);
  let minutes = bay.minutes;
  let hasTimeSlot = false;

  if (pinBehavior === 'TimeSlotStrict') {
    hasTimeSlot = timeSlotStart !== undefined && timeSlotEnd !== undefined;
  } else if (pinBehavior === 'TimeSlotLeadTimeDuration') {
    hasTimeSlot = timeSlotStart !== undefined;
  } else if (pinBehavior === 'TimeSlotDuration') {
    hasTimeSlot = timeSlotStart !== undefined;
  }
  let isWithinTimeSlot = getIsWithinTimeSlot(pinLeadTimeMinutes, hasTimeSlot, timeSlotStart, timeSlotEnd);

  switch (pinBehavior) {
    case 'TimeSlotStrict':
      if (hasTimeSlot) {
        if (isWithinTimeSlot && timeSlotEnd !== undefined) {
          const seconds = (timeSlotEnd!.getTime() - new Date().getTime()) / 60000; // Convert milliseconds to minutes
          return Math.max(seconds > 0 ? seconds : 0, 0);
        }
        return 0;
      }
      break;
    case 'TimeSlotLeadTimeDuration':
      // Get "lead_time_minutes" for free
      if (hasTimeSlot) {
        if (isWithinTimeSlot && timeSlotStart !== undefined) {
          // Get the extra lead time
          const leadTime = Math.max((timeSlotStart!.getTime() - new Date().getTime()) / 60000, 0);

          if (startTime) {
            const seconds =
                minutes - (new Date().getTime() - startTime.getTime()) / 60000; // Convert milliseconds to minutes
            return Math.max(seconds > 0 ? seconds : 0, 0) + Math.max(leadTime > 0 ? leadTime : 0, 0);
          }
          return Math.max(minutes + (leadTime > 0 ? leadTime : 0), 0);
        }
        return 0;
      }
      break;
    case 'TimeSlotDuration':
      // Not get "lead_time_minutes" for free.
      if (hasTimeSlot) {
        if (isWithinTimeSlot) {
          if (startTime) {
            const seconds =
                minutes - (new Date().getTime() - startTime.getTime()) / 60000; // Convert milliseconds to minutes
            return Math.max(seconds > 0 ? seconds : 0, 0);
          }
          return Math.max(minutes, 0);
        }
        return 0;
      }
      break;
    case 'Duration':
      break;
  }
  // Default we assume Duration based
  if (startTime !== undefined) {
    const seconds = minutes - (new Date().getTime() - startTime!.getTime()) / 60000; // Convert milliseconds to minutes
    return Math.max(seconds > 0 ? seconds : 0, 0);
  }
  return Math.max(minutes, 0);
}