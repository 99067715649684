import {
  USERLOADING,
  USERLOADED,
  USERCREATING,
  USERCREATED,
  USERUPDATING,
  USERUPDATED,
} from "./users.actions.types";
import axios from "axios";
import { v1 as uuid } from "uuid";
import { UserModel, UserSignupModel } from "../../models/users";

export const getUser = (userId: string) => (dispatch: Function) => {
  dispatch({ type: USERLOADING });
  axios
    .get(`${process.env.REACT_APP_BASE_URL}/Users/${userId}`)
    .then((result) => {
      if (result.data.length > 0) {
        var user: UserModel = result.data[0];
        if (user !== null && user !== undefined) {
          dispatch({ type: USERLOADED, payload: user });
        }
      }
    });
};

export const getFacilityUsers = (facilityId: string) => (
  dispatch: Function
) => {
  dispatch({ type: USERLOADING });
  axios
    .get(`${process.env.REACT_APP_BASE_URL}/Users/${facilityId}`)
    .then((result) => {
      dispatch({ type: USERLOADED, payload: result.data });
    });
};

export const searchFacilityUsers = (facilityId: string, searchText: string) => (
  dispatch: Function
) => {
  dispatch({ type: USERLOADING });
  axios
    .get(`${process.env.REACT_APP_BASE_URL}/Users/${facilityId}/${searchText}`)
    .then((result) => {
      dispatch({ type: USERLOADED, payload: result.data });
    });
};

export const createUser = (user: UserSignupModel) => (dispatch: Function) => {
  dispatch({ type: USERCREATING });
  axios
    .post(`${process.env.REACT_APP_BASE_URL}/Users/${uuid()}`, user, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((result) => {
      dispatch({ type: USERCREATED, payload: result.data });
    });
};

export const updateUser = (user: UserModel) => (dispatch: Function) => {
  dispatch({ type: USERUPDATING });
  axios
    .put(`${process.env.REACT_APP_BASE_URL}/Users/${user.id}/update`, user)
    .then((result) => {
      console.log(result.request);
      console.log(result);
      dispatch({ type: USERUPDATED, payload: result.data });
    });
};
