import React, { useEffect } from "react";
import {
    Dialog,
    DialogContent,
    makeStyles,
    Theme,
    createStyles,
    Toolbar,
    IconButton,
    Typography,
    Button,
    Snackbar,
    MuiThemeProvider,
    useTheme,
    TextField,
    FormControl, FormControlLabel, Checkbox,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Alert } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Facility } from "../../models/facility";
import { pinErrorHandled } from '../../redux/actions/pins.actions';
import axios, { AxiosError } from "axios";
import { PINERROR } from "../../redux/actions/pins.actions.types";
import {BayDbModel, BayPIN, Pin} from "../../models/bays";
import { FindPin } from "./FindPins";

interface FindPinParams {
    createdAt?: Date;
}

interface FindPinOptionsProps {
    show: boolean;
    handleClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            position: "relative",
        },
        title: {
            marginLeft: theme.spacing(1),
            flex: 1,
            textAlign: "left",
            font: "normal normal normal 36px/44px Montserrat",
            letterSpacing: "0px",
            color: "#041A27",
            opacity: "1",
        },
        root: {
            padding: theme.spacing(2),
            background: "white",
            "& > *": {
                margin: theme.spacing(1),
            },
        },
        notime: {
            color: "red",
        },
        time: {
            color: "primary",
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    })
);

export const FindPinOptions: React.FC<FindPinOptionsProps> = ({
    show,
    handleClose
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();

    const [pins, setPins] = React.useState<Pin[]>([]);

    const [snackBarOpen, setSnackBarOpen] = React.useState(false);
    const [snackBarMessage, setSnackBarMessage] = React.useState("");

    const [showFindPin, setShowFindPin] = React.useState(false);

    const [searchPin, setSearchPin] = React.useState("");

    const [last30Days, setLast30Days] = React.useState(true);

    const allowGroupPinOption = useSelector<RootState, boolean>(
        (selector) => (selector.facility.selectedFacility.pinGroupOptionStr.toLowerCase()  === 'allow')
    );

    const bays = useSelector<RootState, BayDbModel[]>(
        (selector) => selector.bays.bays
    );

    const handleCloseFindPinOptions = () => {
        setShowFindPin(false)
        setSearchPin("")
        setPins([])
        setLast30Days(true)
        handleClose()
        dispatch(pinErrorHandled());
    };

    const handleFindPinChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        setSearchPin(event.target.value as string)
    };


    const handleFindPin = () => {
        let params: FindPinParams = {}

        if (last30Days) {
            const createdAt = new Date();
            createdAt.setDate(createdAt.getDate() - 30);
            createdAt.setMinutes(0, 0, 0);
            params.createdAt = createdAt;
        }

        axios
            .get(`${process.env.REACT_APP_BASE_URL}/${selectedFacility.uuid}/Pins/${searchPin}/list`, {
                params
            })
            .then((res) => {
                var pinsReturned: Pin[] = res.data;

                // Pins Bay Number to Bay Name
                pinsReturned.forEach((pin) => {
                    if (pin.bayId) {
                        const bay = bays.find((bay) => bay.uuid === pin.bayId);
                        if (bay) {
                            pin.bayName = bay.bayName;
                        }
                    }
                });

                setPins(pinsReturned);
            })
            .catch((error: AxiosError) => {
                dispatch({
                    type: PINERROR,
                    payload: (error.response !== undefined)
                        ? error.response.data?.message
                        : error.message
                });
            }).finally(() => {
                setShowFindPin(true)
            });
    };

    const handleSnackBarClose = (
        event?: React.SyntheticEvent,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }

        setSnackBarOpen(false);
    };

    const selectedFacility = useSelector<RootState, Facility>(
        (selector) => selector.facility.selectedFacility
    );

    const handleLast30DaysChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLast30Days(event.target.checked);
    };

    return (
        <MuiThemeProvider theme={theme}>
            <Dialog
                open={show}
                onClose={handleCloseFindPinOptions}
                aria-labelledby="form-dialog-title">
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        Find Booking
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleCloseFindPinOptions}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <React.Fragment>
                        <div className="TextField-with-border-radius">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={last30Days}
                                        onChange={handleLast30DaysChange}
                                        color="primary"
                                    />
                                }
                                label="Past 30 Days and Upcoming"
                            />
                            <FormControl component="fieldset" style={{ marginBottom: "10px" }} fullWidth={true}>
                                <TextField
                                    label="Enter Pin Number"
                                    variant="standard"
                                    color="primary"
                                    value={searchPin}
                                    inputProps={{
                                        maxLength: 4,
                                    }}
                                    error={
                                        (searchPin !== "" &&
                                            (isNaN(Number(searchPin)) || searchPin.length !== 4)) ||
                                        (searchPin.length === 4)
                                    }
                                    helperText={
                                        searchPin != ""
                                            ? searchPin.length === 4
                                                ? !isNaN(Number(searchPin))
                                                    ? ""
                                                    : "Please enter a valid PIN"
                                                : "Please enter a full 4 digit PIN"
                                            : ""
                                    }
                                    onChange={handleFindPinChange}
                                />
                            </FormControl>
                            <FormControl variant="outlined" className={classes.formControl}
                                fullWidth={true}>
                                <Button variant="contained"
                                    color="primary"
                                    style={{ minWidth: '400px' }}
                                    fullWidth={true}
                                    onClick={handleFindPin}
                                >
                                    Find
                                </Button>
                            </FormControl>
                        </div>
                        <FindPin
                            show={showFindPin}
                            pins={pins}
                            selectedFacility={selectedFacility}
                            allowGroupPinOption={allowGroupPinOption}
                            handleClose={handleCloseFindPinOptions}
                        />
                    </React.Fragment>
                    <Snackbar
                        open={snackBarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackBarClose}
                    >
                        <Alert onClose={handleSnackBarClose} severity="success">
                            {snackBarMessage}
                        </Alert>
                    </Snackbar>
                </DialogContent>
            </Dialog>
        </MuiThemeProvider>
    );
};
