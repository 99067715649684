import { SettingsModel } from "../../models/settings";
import React, { useState } from "react";
import {
  TextField,
  Dialog,
  DialogContent,
  makeStyles,
  Theme,
  createStyles,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  FormControl,
  MuiThemeProvider,
  useTheme,
  Grid,
  Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import MaskedInput from "react-text-mask";
import { useDispatch } from "react-redux";
import { v1 as uuid } from "uuid";
import { createSetting } from "../../redux/actions/settings.actions";

interface SettingCreatorProps {
  show: boolean;
  handleClose: () => void;
  facilityId: string;
}

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function TextMaskCustom(props: TextMaskCustomProps) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        "(",
        /[1-9]/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    root: {
      background: "white",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    notime: {
      color: "red",
    },
    time: {
      color: "primary",
    },
    rootStyle: {
      borderRadius: 25,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
);

export const SettingCreator: React.FC<SettingCreatorProps> = ({
  show,
  handleClose,
  facilityId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [value, setValue] = useState("");
  const [type, setType] = useState("");

  const handleCreate = () => {
    var createdSetting: SettingsModel = {
      id: uuid(),
      facilityId: facilityId,
      name: name,
      description: description,
      value: value,
      type: type,
    };

    dispatch(createSetting(createdSetting));
    handleClose();
  };

  const handleNameChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setName(event.target.value as string);
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setDescription(event.target.value as string);
  };

  const handleValueChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setValue(event.target.value as string);
  };

  const handleTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setType(event.target.value as string);
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Dialog
        classes={{
          paper: classes.rootStyle,
        }}
        open={show}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Setting Creation
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <React.Fragment>
            <div className="TextField-with-border-radius">
              {
                <div>
                  <Grid>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <TextField
                        id="name"
                        label="Name"
                        type="text"
                        color="primary"
                        value={name}
                        onChange={handleNameChange}
                        variant="outlined"
                      />
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <TextField
                        id="description"
                        label="Description"
                        type="text"
                        color="primary"
                        value={description}
                        onChange={handleDescriptionChange}
                        variant="outlined"
                      />
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <TextField
                        id="value"
                        label="Value"
                        type="text"
                        color="primary"
                        value={value}
                        onChange={handleValueChange}
                        variant="outlined"
                      />
                    </FormControl>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <TextField
                        id="type"
                        label="Type"
                        type="text"
                        color="primary"
                        value={type}
                        onChange={handleTypeChange}
                        variant="outlined"
                      />
                    </FormControl>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCreate}
                    >
                      Create Setting
                    </Button>
                  </Grid>
                </div>
              }
            </div>
          </React.Fragment>
        </DialogContent>
      </Dialog>
    </MuiThemeProvider>
  );
};

export default SettingCreator;
