import {
  BAYSLOADING,
  BAYSLOADED,
  BAYLOADING,
  BAYLOADED,
  BAYUPDATING,
  BAYUPDATED,
} from "./bays.actions.types";
import axios from "axios";
import { BayDbModel } from "../../models/bays";
import { Facility } from '../../models/facility';

export const getBay = (facility: string, bay: string) => (
  dispatch: Function
) => {
  dispatch({ type: BAYLOADING });
  axios
    .get(`${process.env.REACT_APP_BASE_URL}/${facility}/Bays/${bay}`)
    .then((res) => {
      dispatch({ type: BAYLOADED, payload: res.data });
    });
};

export const getBays = (facility: string) => (dispatch: Function) => {
  dispatch({ type: BAYSLOADING });

    const BOOKINGS_BASE_URL = 'https://dev-api.inrangegolf.com/bookings/v1';
  axios
    // .get(`${process.env.REACT_APP_BASE_URL}/${facility}/Bays`)
    .get(`${BOOKINGS_BASE_URL}/ranges/${facility}/Bays`)
    .then((res) => {
      var baysReturned = res.data.map((bay: any) => {
          return {
              ...bay,
              uuid: bay.bayId,
              pin: bay?.booking?.pin,
              pinId: bay?.booking?.id,
          } as BayDbModel;
      });

      var facManBays = baysReturned.sort(
        (a: BayDbModel, b: BayDbModel): number => {
          if (a.bayName < b.bayName) return -1;
          if (a.bayName > b.bayName) return 1;
          return 0;
        }
      );
      dispatch({ type: BAYSLOADED, payload: facManBays });
    });
};

export const assignBayPin = (
  pin: string,
  minutes: number,
  bayId: string,
  facility: string
) => (dispatch: Function) => {
  dispatch({ type: BAYUPDATING });

  axios
    .put(`${process.env.REACT_APP_BASE_URL}/${facility}/Bays/${bayId}/pin`, {
      minutes: minutes,
      pin: pin,
    })
    .then((res) => {
      dispatch({ type: BAYUPDATED, payload: res.data });
    });
};

export const changeBayState = (
  bay: BayDbModel,
  status: number,
  facility: string
) => (dispatch: Function) => {
  dispatch({ type: BAYUPDATING });

  axios
    .put(
      `${process.env.REACT_APP_BASE_URL}/${facility}/Bays/${bay.uuid}/status`,
      { ...bay, bay_state_id: status }
    )
    .then((res) => {
      dispatch({ type: BAYUPDATED, payload: res.data });
    });
};

export const updateBay = (bay: BayDbModel, facility: Facility) => (dispatch: Function) => {
    if (facility !== null && facility.uuid === bay.facility_id) {
        dispatch({ type: BAYUPDATED, payload: bay });
    }
};
