// import { LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT } from "./auth.actions.types";
import axios from "axios";
import { Facility } from "../../models/facility";

export const FACILITY_CHANGE = "FACILITY_CHANGE";
export const FACILITY_LOADED = "FACILITY_LOADED";
export const FACILITY_UPDATING = "FACILITY_UPDATING";
export const FACILITY_UPDATED = "FACILITY_UPDATED";

export interface FacilityState {
  selectedFacility: Facility;
  role: string;
  facilities: Facility[];
}

// get selected facility
export const selectFacility = (facilityId: string, role: string) => (
  dispatch: Function
) => {
  axios
    .get(`${process.env.REACT_APP_BASE_URL}/facilities/${facilityId}`)
    .then((res) => {
      dispatch({
        type: FACILITY_CHANGE,
        payload: { facility: res.data, role: role },
      });
    });
};

// export const getFacility = (facilityId: string) => (dispatch: Function) => {
//   axios
//     .get(`${process.env.REACT_APP_BASE_URL}/facilities/${facilityId}`)
//     .then((res) => {
//       dispatch({
//         type: FACILITY_SELECTED,
//         payload: { facility: res.data },
//       });
//     });
// };

export const updateFacility = (
  facilityId: string,
  updatedFacility: Facility
) => (dispatch: Function) => {
  dispatch({ type: FACILITY_UPDATING });
  axios
    .put(`${process.env.REACT_APP_BASE_URL}/facilities/${facilityId}`, {
        id: updatedFacility.id,
        uuid: updatedFacility.uuid,
        name: updatedFacility.name,
        description: updatedFacility.description,
        defaultPlayMode: updatedFacility.defaultPlayMode,
        timeZone: updatedFacility.timeZone,
        currency: updatedFacility.currency,
        phoneNumberFormat: updatedFacility.phoneNumberFormat,
        pinBehaviorStr: updatedFacility.pinBehaviorStr,
        pinLeadTimeMinutes: updatedFacility.pinLeadTimeMinutes,
        pinGroupOptionStr: updatedFacility.pinGroupOptionStr,
    })
    .then((result) => {
      dispatch({ type: FACILITY_UPDATED, payload: result.data });
    });
};
