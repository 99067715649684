import { UserModel } from "../../models/users";

export const USERUPDATING = "USERUPDATING";
export const USERUPDATED = "USERUPDATED";
export const USERLOADING = "USERLOADING";
export const USERLOADED = "USERLOADED";
export const USERCREATING = "USERCREATING";
export const USERCREATED = "USERCREATED";

export interface UsersState {
  isLoading: boolean;
  users: UserModel[];
}
