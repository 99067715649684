import React, { useState, useEffect } from "react";
import "../../styles/Bay.css";
import { BaySettings } from "./BaySettings";
import { BayStatus, BayPIN, BayDbModel } from "../../models/bays";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import { getBayPin } from "../../redux/actions/pins.actions";
import {
  AppBar,
  Typography,
  Theme,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import { Facility } from "../../models/facility";
import Bay from "./Bay";
import BayLayoutItem from "./BayLayoutItem";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    loading: {
      display: "flex",
      "& > * + *": {
        marginLeft: theme.spacing(2),
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      translate: 50,
      textAlign: "center",
    },
    floor: {
      background: "black",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
);

export const BayLayoutView: React.FC = () => {
  const classes = useStyles();

  const bays = useSelector<RootState, BayDbModel[]>(
    (selector) => selector.bays.bays
  );

  return (
    <React.Fragment>
      {bays.filter((x) => x.bayFloor === 1).length > 0 && (
        <React.Fragment>
          <svg x="0" y="0" viewBox="0 -10 1600 250">
            <polyline
              points="60,10 60,0 475,150 1125,150 1525,0 1525,70 1125,220 475, 220 60,70 60,10"
              className="baysLayoutShape"
            />
            <foreignObject className="node" height="250px" width="1600px">
              <div
              // style={{
              //   position: "fixed",
              // }}
              >
                <Typography
                  variant="h6"
                  color="secondary"
                  className={classes.title}
                >
                  <div className="baysLayoutFloorLabel">Floor 1</div>
                </Typography>
                <div className="baysLayoutContainer">
                  <div className="baysLayoutLeft">
                    {bays
                      .filter((x) => x.bayFloor === 1)
                      .slice(0, 4)
                      .map((bay) => {
                        return <BayLayoutItem key={bay.uuid} bay={bay} />;
                      })}
                  </div>

                  <div className="baysLayoutCenter">
                    {bays
                      .filter((x) => x.bayFloor === 1)
                      .slice(4, 16)
                      .map((bay) => {
                        return <BayLayoutItem key={bay.uuid} bay={bay} />;
                      })}
                  </div>

                  <div className="baysLayoutRight">
                    {bays
                      .filter((x) => x.bayFloor === 1)
                      .slice(16, 20)
                      .map((bay) => {
                        return <BayLayoutItem key={bay.uuid} bay={bay} />;
                      })}
                  </div>
                </div>
              </div>
            </foreignObject>
          </svg>
        </React.Fragment>
      )}
      {bays.filter((x) => x.bayFloor === 2).length > 0 && (
        <React.Fragment>
          <svg x="0" y="0" viewBox="0 -10 1600 250">
            <polyline
              points="60,10 60,0 475,150 1125,150 1525,0 1525,70 1125,220 475, 220 60,70 60,10"
              className="baysLayoutShape"
            />
            <foreignObject
              className="node"
              x="0"
              y="0"
              height="250px"
              width="1600px"
            >
              <Typography
                variant="h6"
                color="secondary"
                className={classes.title}
              >
                <div className="baysLayoutFloorLabel">Floor 2</div>
              </Typography>
              <div className="baysLayoutContainer">
                <div className="baysLayoutLeft">
                  {bays
                    .filter((x) => x.bayFloor === 2)
                    .slice(0, 4)
                    .map((bay) => {
                      return <BayLayoutItem key={bay.uuid} bay={bay} />;
                    })}
                </div>
                <div className="baysLayoutCenter">
                  {bays
                    .filter((x) => x.bayFloor === 2)
                    .slice(4, 16)
                    .map((bay) => {
                      return <BayLayoutItem key={bay.uuid} bay={bay} />;
                    })}
                </div>

                <div className="baysLayoutRight">
                  {bays
                    .filter((x) => x.bayFloor === 2)
                    .slice(16, 20)
                    .map((bay) => {
                      return <BayLayoutItem key={bay.uuid} bay={bay} />;
                    })}
                </div>
              </div>
            </foreignObject>
          </svg>
        </React.Fragment>
      )}
      {bays.filter((x) => x.bayFloor === 3).length > 0 && (
        <React.Fragment>
          <svg x="0" y="0" viewBox="0 -10 1600 250">
            <polyline
              points="60,10 60,0 475,150 1125,150 1525,0 1525,70 1125,220 475, 220 60,70 60,10"
              className="baysLayoutShape"
            />
            <foreignObject
              className="node"
              x="0"
              y="0"
              height="250px"
              width="1600px"
            >
              <Typography
                variant="h6"
                color="secondary"
                className={classes.title}
              >
                <div className="baysLayoutFloorLabel">Floor 3</div>
              </Typography>
              <div className="baysLayoutContainer">
                <div className="baysLayoutLeft">
                  {bays
                    .filter((x) => x.bayFloor === 3)
                    .slice(0, 4)
                    .map((bay) => {
                      return <BayLayoutItem key={bay.uuid} bay={bay} />;
                    })}
                </div>
                <div className="baysLayoutCenter">
                  {bays
                    .filter((x) => x.bayFloor === 3)
                    .slice(4, 16)
                    .map((bay) => {
                      return <BayLayoutItem key={bay.uuid} bay={bay} />;
                    })}
                </div>

                <div className="baysLayoutRight">
                  {bays
                    .filter((x) => x.bayFloor === 3)
                    .slice(16, 20)
                    .map((bay) => {
                      return <BayLayoutItem key={bay.uuid} bay={bay} />;
                    })}
                </div>
              </div>
            </foreignObject>
          </svg>
        </React.Fragment>
      )}
        {bays.filter((x) => x.bayFloor === 4).length > 0 && (
            <React.Fragment>
                <svg x="0" y="0" viewBox="0 -10 1600 250">
                    <polyline
                        points="60,10 60,0 475,150 1125,150 1525,0 1525,70 1125,220 475, 220 60,70 60,10"
                        className="baysLayoutShape"
                    />
                    <foreignObject
                        className="node"
                        x="0"
                        y="0"
                        height="250px"
                        width="1600px"
                    >
                        <Typography
                            variant="h6"
                            color="secondary"
                            className={classes.title}
                        >
                            <div className="baysLayoutFloorLabel">Floor 4</div>
                        </Typography>
                        <div className="baysLayoutContainer">
                            <div className="baysLayoutLeft">
                                {bays
                                    .filter((x) => x.bayFloor === 4)
                                    .slice(0, 4)
                                    .map((bay) => {
                                        return <BayLayoutItem key={bay.uuid} bay={bay} />;
                                    })}
                            </div>
                            <div className="baysLayoutCenter">
                                {bays
                                    .filter((x) => x.bayFloor === 4)
                                    .slice(4, 16)
                                    .map((bay) => {
                                        return <BayLayoutItem key={bay.uuid} bay={bay} />;
                                    })}
                            </div>

                            <div className="baysLayoutRight">
                                {bays
                                    .filter((x) => x.bayFloor === 4)
                                    .slice(16, 20)
                                    .map((bay) => {
                                        return <BayLayoutItem key={bay.uuid} bay={bay} />;
                                    })}
                            </div>
                        </div>
                    </foreignObject>
                </svg>
            </React.Fragment>
        )}
    </React.Fragment>
  );
};

export default BayLayoutView;
