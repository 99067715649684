import {
  BAYSLOADING,
  BAYSLOADED,
  BAYUPDATED,
  BayState,
  BAYLOADING,
  BAYLOADED,
} from "../actions/bays.actions.types";
import { BayDbModel } from "../../models/bays";

const initialState: BayState = { isLoading: false, bays: [] };

export default (state: BayState = initialState, action: any) => {
  switch (action.type) {
    case BAYSLOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case BAYSLOADED: {
      return { ...state, bays: action.payload, isLoading: false };
    }
    case BAYUPDATED: {
      // Incoming bay needs to match the rest of the bays facility_id
      if (state.bays.length > 0) {
        if (state.bays[0].facility_id !== action.payload.facility_id) {
          console.log('BayUpdate: FacilityId mismatch!');
          return state;
        }
      }

      // Bay number is missing from the payload :(
      const bayUpdated = state.bays.find((x) => x.uuid === action.payload.uuid)
      action.payload.bay_number = bayUpdated?.bayNumber;

      return {
        ...state,
        bays: [
          ...state.bays.filter((x) => x.uuid !== action.payload.uuid),
          action.payload,
        ].sort((a: BayDbModel, b: BayDbModel): number => {
          if (a.bayName < b.bayName) return -1;
          if (a.bayName > b.bayName) return 1;
          return 0;
        }),
      };
    }
    case BAYLOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case BAYLOADED: {
      // Bay number is missing from the payload :(
      const bayUpdated = state.bays.find((x) => x.uuid === action.payload.uuid)
      action.payload.bay_number = bayUpdated?.bayNumber;

      return {
        ...state,
        bays: [
          ...state.bays.filter((x) => x.uuid !== action.payload.uuid),
          action.payload,
        ].sort((a: BayDbModel, b: BayDbModel): number => {
          if (a.bayName < b.bayName) return -1;
          if (a.bayName > b.bayName) return 1;
          return 0;
        }),
        isLoading: false,
      };
    }
    default:
      return state;
  }
};
