import React, {useEffect} from "react";
import {
    Dialog,
    DialogContent,
    makeStyles,
    Theme,
    createStyles,
    Toolbar,
    IconButton,
    Typography,
    Snackbar,
    MuiThemeProvider,
    useTheme,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Divider,
    Grid,
    Button,
    FormLabel,
    FormControlLabel, Checkbox, RadioGroup, Radio, TextField
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Alert } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { pinErrorHandled } from '../../redux/actions/pins.actions';
import { BayDbModel, Pin } from "../../models/bays";
import { RootState } from "../../redux/store";
import {
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker } from "@material-ui/pickers";
import axios from "axios";
import {Facility} from "../../models/facility";

interface LinkPinProps {
    pin: Pin;
    show: boolean;
    selectedFacility: Facility;
    handleSubmit: () => void;
    handleClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            position: "relative",
        },
        title: {
            marginLeft: theme.spacing(1),
            flex: 1,
            textAlign: "left",
            font: "normal normal normal 36px/44px Montserrat",
            letterSpacing: "0px",
            color: "#041A27",
            opacity: "1",
        },
        root: {
            padding: theme.spacing(2),
            background: "white",
            "& > *": {
                margin: theme.spacing(1),
            },
        },
        notime: {
            color: "red",
        },
        time: {
            color: "primary",
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        cellHeading: {
            textAlign: "center",
            font: "normal normal bold 16px/19px Montserrat",
            letterSpacing: "0px",
            color: "#041A27",
            textTransform: "uppercase",
            opacity: 1
        },
        cellEntry: {
            textAlign: "center",
            font: "normal normal normal 16px/19px Montserrat",
            letterSpacing: "0px",
            color: "#041A27",
            opacity: "1",
        },
        cellEntryBold: {
            textAlign: "center",
            font: "normal normal bold 16px/19px Montserrat",
            letterSpacing: "0px",
            color: "#041A27",
            opacity: "1",
        },
        noPins: {
            marginLeft: theme.spacing(1),
            flex: 1,
            textAlign: "left",
            font: "normal normal normal 12px/24px Montserrat",
            letterSpacing: "0px",
            color: "#041A27",
            opacity: 1
        },
    })
);

export const UpdatePin: React.FC<LinkPinProps> = ({
                                                    pin,
                                                    show,
                                                    selectedFacility,
                                                    handleSubmit,
                                                    handleClose
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();

    const bays = useSelector<RootState, BayDbModel[]>(
        (selector) => selector.bays.bays
    );

    const [snackBarOpen, setSnackBarOpen] = React.useState(false);
    const [snackBarMessage, setSnackBarMessage] = React.useState("");

    const [errorPinTimeSlotMessage, setErrorPinTimeSlotMessage] = React.useState('');
    const [timeSlotStart, setTimeSlotStart] = React.useState<Date | null>(
        pin.timeSlotStart ? new Date(pin.timeSlotStart) : null
    );
    const [timeSlotEnd, setTimeSlotEnd] = React.useState<Date | null>(
        pin.timeSlotEnd ? new Date(pin.timeSlotEnd) : null
    );
    const [mode, setMode] = React.useState(pin.playMode);

    const [baysSelected, setBaysSelected] = React.useState<string[]>([]);
    const [welcomeMessage, setWelcomeMessage] = React.useState<string | null>(pin.welcomeMessage);

    const [playerFirstName, setPlayerFirstName] = React.useState<string | undefined>(pin.playerName ?? undefined);
    const [playerLastName, setPlayerLastName] = React.useState<string | undefined>(pin.playerLastName ?? undefined);
    const [playerEmail, setPlayerEmail] = React.useState<string | undefined>(pin.playerEmail ?? undefined);

    const [isPlayerEmailValid, setIsPlayerEmailValid] = React.useState(false);
    const [isPlayerFirstNameValid, SetIsPlayerFirstNameValid] = React.useState(false);
    const [isPlayerLastNameValid, SetIsPlayerLastNameValid] = React.useState(false);

    const [showPlayerDetails, setShowPlayerDetails] = React.useState(pin.playerId !== null);
    const [isLinkPlayer, setIsLinkPlayer] = React.useState(pin.playerId !== null);

    useEffect(() => {
        if (show) {
            const bayNames = [];
            if (pin.isGroupPin) {
                const bayNumbers = pin.bayNumbers ?? [];
                bayNumbers.forEach((bayNumber) => {
                    const bay = bays.find((b) => b.bayNumber === bayNumber);
                    if (bay) {
                        bayNames.push(bay.bayName);
                    }
                });

            } else if (pin.bayId) {
                const bay = bays.find((b) => b.uuid === pin.bayId)
                if (bay) {
                    bayNames.push(bay.bayName);
                }
            }
            setBaysSelected(bayNames);
        }
    }, []);

    const handleWelcomeMessageChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        const newMessage = (event.target.value as string).trim() === '' ? null : event.target.value as string;
        setWelcomeMessage(newMessage);
    };

    const handleBaySelectedChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        if (pin.isGroupPin) {
            const bays = (event.target.value as string[]);
            bays.sort((a, b) => a.localeCompare(b));
            setBaysSelected(bays);
        } else {
            setBaysSelected([event.target.value as string]);
        }
    };

    const handleCloseUpdatePins = () => {
        handleClose()
        dispatch(pinErrorHandled());
    };

    const handleSubmitUpdatePin = () => {
        updatePin(pin.id)
    }

    const handleSnackBarClose = (
        event?: React.SyntheticEvent,
        reason?: string
    ) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackBarOpen(false);
    };

    const handlePlayerEmailChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        playerEmailChange(event.target.value as string)
    };

    const handlePlayerFirstNameChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        playerFirstNameChange(event.target.value as string)
    };

    const handlePlayerLastNameChange = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        playerLastNameChange(event.target.value as string)
    };

    function playerLastNameChange(value: string | undefined) {
        if (value === null || value === undefined || value.trim().length === 0) {
            setPlayerLastName(undefined);
            SetIsPlayerLastNameValid(false);
        } else {
            setPlayerLastName(value);
            SetIsPlayerLastNameValid(true);
        }
    };

    function playerEmailChange(value: string | undefined) {
        if (value === null || value === undefined || value.trim().length === 0) {
            setPlayerEmail(undefined);
            setIsPlayerEmailValid(false);
        } else {
            setPlayerEmail(value);
            setIsPlayerEmailValid(validateEmail(value));
        }
    }

    function playerFirstNameChange(value: string | undefined) {
        if (value === null || value === undefined || value.trim().length === 0) {
            setPlayerFirstName(undefined);
            SetIsPlayerFirstNameValid(false);
        } else {
            setPlayerFirstName(value);
            SetIsPlayerFirstNameValid(true);
        }
    }

    const handleIsLinkPinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsLinkPlayer(event.target.checked);
        setShowPlayerDetails(!isLinkPlayer)
    };

    useEffect(() => {
        playerEmailChange(pin.playerEmail ?? undefined);
        playerFirstNameChange(pin.playerName ?? undefined);
        playerLastNameChange(pin.playerLastName ?? undefined);
    }, [pin]);

    const validateEmail = (email:string) => {
        const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return regex.test(email);
    };

    const handleModeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setMode(event.target.value as string);
    };

    const updatePin = (pinId: string) => {
        setErrorPinTimeSlotMessage('');
        if (timeSlotStart === null || timeSlotEnd === null) {
            setErrorPinTimeSlotMessage('Invalid start and end time slot');
            return
        }

        const diff = timeSlotEnd.getTime() - timeSlotStart.getTime()
        if (diff <= 0) {
            setErrorPinTimeSlotMessage('Invalid start and end time slot');
            return
        }

        // Set seconds and milliseconds to zero
        if (timeSlotStart) {
            timeSlotStart.setSeconds(0, 0);
        }
        if (timeSlotEnd) {
            timeSlotEnd.setSeconds(0, 0);
        }

        const minutes = Math.floor((diff / 1000) / 60);

        // Convert to bay numbers from bay names
        const bayDbModels = baysSelected.map((bayName) => bays.find((b) => b.bayName === bayName)).filter(Boolean) as BayDbModel[];
        const bayNumbers = bayDbModels.map((bay) => bay.bayNumber);

        const data: {
            playMode: string;
            minutes: number;
            timeSlotStart: Date;
            timeSlotEnd: Date;
            welcomeMessage: string | undefined;
            bayNumber: number | undefined;
            bayNumbers: number[] | undefined;
            player: {
                firstName: string | undefined;
                lastName: string | undefined;
                email: string | undefined;
            } | undefined;
        } = {
            minutes: minutes,
            playMode: mode,
            timeSlotStart: timeSlotStart,
            timeSlotEnd: timeSlotEnd,
            welcomeMessage: welcomeMessage ? (welcomeMessage.trim().length === 0 ? undefined : welcomeMessage) : undefined,
            bayNumber: pin.isGroupPin ? undefined : (bayNumbers.length === 1 ? bayNumbers[0] : undefined),
            bayNumbers: bayNumbers.length === 0 ? undefined : bayNumbers,
            player: isLinkPlayer ?
                {
                    firstName: playerFirstName,
                    lastName: playerLastName,
                    email: playerEmail
                } : undefined,
        };

        axios
            .put(
                `${process.env.REACT_APP_BASE_URL}/facilities/${selectedFacility.uuid}/Pins/${pinId}`,
                data
            )
            .then((res) => {
                handleSubmit()
            })
            .catch(reason => console.log(reason));
    };

    return (
        <MuiThemeProvider theme={theme}>
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={show}
                onClose={handleCloseUpdatePins}
                aria-labelledby="form-dialog-title">

                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        Update Booking: {pin?.pin}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleCloseUpdatePins}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <React.Fragment>
                        <Divider />
                        <div className="TextField-with-border-radius">
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth={true}>
                                        <FormLabel component="legend">Link Booking to a Bay</FormLabel>
                                        <Select
                                            id="bays"
                                            label="Bay"
                                            color="primary"
                                            value={baysSelected}
                                            multiple={pin.isGroupPin}
                                            onChange={handleBaySelectedChange}
                                            variant="outlined"
                                            renderValue={(selected) => (
                                                <div>
                                                    { baysSelected.map((value, index) => (
                                                        <span key={value}>{ value }{ index !== baysSelected.length - 1 && ',' } </span>
                                                    ))}
                                                </div>
                                            )}
                                        >
                                            {bays.map((bay, index) => (
                                                <MenuItem key={bay.uuid} value={bay.bayName}>{bay.bayName} ({bay.bayNumber})</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="TextField-with-border-radius">
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Mode</FormLabel>
                                            <RadioGroup row aria-label="mode" name="mode" value={mode}
                                                        onChange={handleModeChange}>
                                                <FormControlLabel value={"MP"} control={<Radio />} label="Inrange+" />
                                                <FormControlLabel value={"SP"} control={<Radio />} label="Inrange" />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    {errorPinTimeSlotMessage && (
                                        <Typography color="error">
                                            {errorPinTimeSlotMessage}
                                        </Typography>
                                    )}
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <FormLabel component="legend">Start Slot</FormLabel>
                                        <DateTimePicker value={timeSlotStart} onChange={setTimeSlotStart}
                                                        style={{minWidth: '100%'}}/>
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={12}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <FormLabel component="legend">End Slot</FormLabel>
                                        <DateTimePicker value={timeSlotEnd} onChange={setTimeSlotEnd}
                                                        style={{minWidth: '100%'}}/>
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined"
                                                 hidden={baysSelected.length <= 0}
                                                 className={classes.formControl} fullWidth={true}>
                                        <FormLabel component="legend">Custom Welcome Message</FormLabel>
                                        <TextField
                                            label="Optional"
                                            variant="outlined"
                                            color="primary"
                                            value={welcomeMessage || ""}
                                            inputProps={{ maxLength: 50 }}
                                            onChange={handleWelcomeMessageChange}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    { !pin.isGroupPin && (
                                        <FormControl component="fieldset">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="checkIsLinkPin"
                                                        color="primary"
                                                        disabled={pin.playerId !== null}
                                                        onChange={handleIsLinkPinChange}
                                                        checked={isLinkPlayer}
                                                    />
                                                }
                                                label="Add player details"
                                                labelPlacement="end"
                                            />
                                        </FormControl>
                                    )}
                                    { showPlayerDetails && (
                                        <FormControl variant="outlined" className={classes.formControl}
                                                     fullWidth={true}>
                                            <TextField
                                                label="Email"
                                                variant="outlined"
                                                color="primary"
                                                disabled={pin.playerId !== null}
                                                value={playerEmail}
                                                onChange={handlePlayerEmailChange}
                                                error={!isPlayerEmailValid}
                                                helperText={!isPlayerEmailValid ? 'Email must be valid!' : ''}
                                            />
                                        </FormControl>
                                    )}
                                    { showPlayerDetails && (
                                        <FormControl variant="outlined" className={classes.formControl}
                                                     fullWidth={true}>
                                            <TextField
                                                label="First name"
                                                variant="outlined"
                                                color="primary"
                                                value={playerFirstName}
                                                onChange={handlePlayerFirstNameChange}
                                                disabled={pin.playerId !== null}
                                                error={!isPlayerFirstNameValid}
                                                helperText={!isPlayerFirstNameValid ? 'First name must be valid!' : ''}
                                            />
                                        </FormControl>
                                    )}
                                    { showPlayerDetails && (
                                        <FormControl variant="outlined" className={classes.formControl}
                                                     fullWidth={true}>
                                            <TextField
                                                label="Last name"
                                                variant="outlined"
                                                color="primary"
                                                disabled={pin.playerId !== null}
                                                value={playerLastName}
                                                onChange={handlePlayerLastNameChange}
                                                error={!isPlayerLastNameValid}
                                                helperText={!isPlayerLastNameValid ? 'Last name must be valid!' : ''}

                                            />
                                        </FormControl>
                                    )}
                                </Grid>



                                <FormControl variant="outlined" className={classes.formControl}
                                             fullWidth={true}>
                                    <Button variant="contained"
                                            color="primary"
                                            style={{minWidth: '400px'}}
                                            fullWidth={true}
                                            disabled={timeSlotEnd === null || timeSlotStart === null}
                                            onClick={handleSubmitUpdatePin}
                                    >
                                        Update Booking
                                    </Button>
                                </FormControl>
                            </Grid>
                        </div>
                    </React.Fragment>
                    <Snackbar
                        open={snackBarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackBarClose}
                    >
                        <Alert onClose={handleSnackBarClose} severity="success">
                            {snackBarMessage}
                        </Alert>
                    </Snackbar>
                </DialogContent>
            </Dialog>
        </MuiThemeProvider>
    );
};
