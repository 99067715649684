import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  typography: {
    // Use the system font.
    fontFamily:
      "-apple-system,system-ui,BlinkMacSystemFont," +
      '"adobe-clean",sans-serif',
  },
  // shape: {
  //   borderRadius: "25px",
  // },
  palette: {
    // type: "dark",
    primary: {
      main: "#f21a92",
      // light: "#ee3ba4",
      // dark: "#ea0a8e",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#7d7d7d",
      // light: "#534bae",
      // dark: "#000051",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#000000",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: "25px",
        height: "50px",
      },
    },
    MuiCard: {
      root: {
        borderRadius: "25px",
      },
    },
    MuiInput: {
      root: {
        borderRadius: "25px",
      },
    },
    // MuiDivider: {
    //   root: {
    //     border: "10px",
    //   },
    // },
  },
});

export default theme;
