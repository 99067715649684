import {
  SETTINGLOADING,
  SETTINGLOADED,
  SETTINGCREATING,
  SETTINGCREATED,
  SETTINGUPDATING,
  SETTINGUPDATED,
} from "./settings.actions.types";
import axios from "axios";
import { SettingsModel } from "../../models/settings";

export const getSetting = (settingName: string, settingFacility: string) => (
  dispatch: Function
) => {
  dispatch({ type: SETTINGLOADING });
  axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/FacilitySettings/${settingFacility}/${settingName}`,
      {
        headers: { "x-api-key": process.env.REACT_APP_APIKEY },
      }
    )
    .then((result) => {
      if (result.data.length > 0) {
        var setting: SettingsModel = result.data[0];
        if (setting !== null && setting !== undefined) {
          dispatch({ type: SETTINGLOADED, payload: setting });
        }
      }
    });
};

export const getSettingsForFacility = (facilityId: string) => (
  dispatch: Function
) => {
  dispatch({ type: SETTINGLOADING });
  axios
    .get(`${process.env.REACT_APP_BASE_URL}/FacilitySettings/${facilityId}`, {
      headers: { "x-api-key": process.env.REACT_APP_APIKEY },
    })
    .then((settings) => {
      if (settings.data.length > 0) {
        dispatch({ type: SETTINGLOADED, payload: settings });
      }
    });
};

export const createSetting = (setting: SettingsModel) => (
  dispatch: Function
) => {
  dispatch({ type: SETTINGCREATING });
  console.log(setting);
  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/FacilitySettings/${setting.id}/${setting.facilityId}/${setting.name}/${setting.description}/${setting.value}/${setting.type}`
    )
    .then((result) => {
      console.log(result);
      dispatch({ type: SETTINGCREATED, payload: result.data });
    });
};

export const updateSetting = (settingId: string, newValue: string) => (
  dispatch: Function
) => {
  dispatch({ type: SETTINGUPDATING });
  axios
    .put(
      `${process.env.REACT_APP_BASE_URL}/FacilitySettings/${settingId}/${newValue}`
    )
    .then((result) => {
      dispatch({ type: SETTINGUPDATED, payload: result.data });
    });
};
