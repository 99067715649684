import {
  BEGIN_LOGIN,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  REFRESH,
  AuthState,
} from "../actions/auth.actions.types";
import { Facility } from '../../models/facility';

const initialState: AuthState = {
  isAuthenticated: !!localStorage.getItem("awflitefmtoken") ? true : false,
  authError: false,
  token: localStorage.getItem("awflitefmtoken") || "",
  userName: "",
  facilityAccess: [],
  isLoading: false,
};

export default (state: AuthState = initialState, action: any) => {
  switch (action.type) {
    case BEGIN_LOGIN: {
      return {
        ...state,
        isAuthenticated: false,
        userName: "",
        token: "",
        authError: false,
        facilityAccess: [],
      };
    }
    case LOGIN_SUCCESS: {
      // Sort it
      let facilities = action.payload.facilities;
      if (facilities !== null && facilities !== undefined) {
        facilities = facilities.sort((a: Facility, b: Facility): number => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
      }

      localStorage.setItem("awflitefmtoken", action.payload.token);
      localStorage.setItem(
        "availablefacilities",
        JSON.stringify(facilities)
      );

      // console.log(action.payload);
      return {
        ...state,
        isAuthenticated: true,
        userName: "",
        token: action.payload.token,
        authError: false,
        facilityAccess: facilities,
      };
    }
    case LOGOUT: {
      localStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
        userName: "",
        token: "",
        authError: false,
        facilityAccess: [],
      };
    }
    case LOGIN_FAIL: {
      localStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
        userName: "",
        token: "",
        authError: true,
        facilityAccess: [],
      };
    }
    case REFRESH: {
      return {
        ...state,
        isAuthenticated: true,
        facilityAccess: action.payload,
      };
    }
    default:
      return state;
  }
};
