import React, { useEffect } from "react";
import {
    TextField,
    Dialog,
    DialogContent,
    makeStyles,
    Theme,
    createStyles,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    FormControl,
    Snackbar,
    CircularProgress,
    MuiThemeProvider,
    useTheme, Button, Grid,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import axios, { AxiosError } from 'axios';
import { Alert } from "@material-ui/lab";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Facility } from "../../models/facility";
import { PINERROR } from '../../redux/actions/pins.actions.types';
import { pinErrorHandled } from '../../redux/actions/pins.actions';
import LinkIcon from "@material-ui/icons/Link";
import {BayDbModel, Pin} from "../../models/bays";
import {LinkPin} from "./LinkPin";

interface CreatePinProps {
    minutes: string;
    mode: string;
    newPin: string;
    description: string;
    welcomeMessage: string | null;
    isStatic: boolean;
    isGroupPin: boolean;
    show: boolean;
    usePinTimeSlot: boolean;
    timeSlotStart: Date | null,
    timeSlotEnd: Date | null,
    bays: BayDbModel[],
    playerFirstname: string | null,
    playerLastname: string | null,
    playerEmail: string | null,
    handleClose: () => void;
    handleRetry: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            position: "relative",
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        root: {
            background: "white",
            "& > *": {
                margin: theme.spacing(1),
            },
        },
        notime: {
            color: "red",
        },
        time: {
            color: "primary",
        },
        formControl: {
            margin: theme.spacing(0),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    })
);

export const CreatePin: React.FC<CreatePinProps> = ({
                                                        minutes,
                                                        mode,
                                                        newPin,
                                                        description,
                                                        welcomeMessage,
                                                        isStatic,
                                                        isGroupPin,
                                                        show,
                                                        usePinTimeSlot,
                                                        timeSlotStart,
                                                        timeSlotEnd,
                                                        bays,
                                                        playerFirstname,
                                                        playerLastname,
                                                        playerEmail,
                                                        handleClose,
                                                        handleRetry,
                                                    }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [pin, setPin] = React.useState(newPin);
    const [loading, setLoading] = React.useState(false);
    const [snackBarOpen, setSnackBarOpen] = React.useState(false);
    const [snackBarMessage, setSnackBarMessage] = React.useState("");

    const [linkPin, setLinkPin] = React.useState<Pin | undefined>(undefined);
    const [showLinkPin, setShowLinkPin] = React.useState(false);

    const theme = useTheme();
    const handleSnackBarClose = (
        event?: React.SyntheticEvent,
        reason?: string
    ) => {
        handleClose();

        if (reason === "clickaway") {
            return;
        }

        setSnackBarOpen(false);
    };

    const handleLinkPin = () => {
        if (linkPin !== undefined) {
            setShowLinkPin(true);
        }
    };

    const handleRetryCreatePinOptions = () => {
        dispatch(pinErrorHandled());

        setLinkPin(undefined);
        setLoading(false);
        setPin("");
        setSnackBarOpen(false);

        handleRetry();
    };

    const selectedFacility = useSelector<RootState, Facility>(
        (selector) => selector.facility.selectedFacility
    );

    const error = useSelector<RootState, Error | undefined>(
        (selector) => selector.pins.errorObject
    );

    const handleLinkPinClose = (
        event?: React.SyntheticEvent,
    ) => {
        setShowLinkPin(false)
        setLinkPin(undefined)

        handleClose()
    };

    const createPin = (
        minutes: number,
        mode: string,
        pin: string,
        description: string,
        timeSlotStart: Date | null,
        timeSlotEnd: Date | null,
        playerFirstname: string | null,
        playerLastname: string | null,
        playerEmail: string | null
    ) => {
        setLoading(true);
        // Prevent previously generated PIN to display by default
        setPin("");

        // Set seconds and milliseconds to zero
        if (timeSlotStart) {
            timeSlotStart.setSeconds(0, 0);
        }
        if (timeSlotEnd) {
            timeSlotEnd.setSeconds(0, 0);
        }

        const bayNumbers = bays.map((bay) => bay.bayNumber);

        const data: {
            minutes: number;
            playMode: string;
            isStatic: boolean;
            PIN: string;
            isGroupPin: boolean;
            description: string;
            welcomeMessage: string | undefined;
            timeSlotStart: Date | null;
            timeSlotEnd: Date | null;
            bayNumber: number | undefined;
            bayNumbers: number[] | undefined;
            player?: {
                firstName: string | null;
                lastname: string | null;
                email: string | null;
            };
        } = {
            minutes: minutes,
            playMode: mode,
            isStatic: isStatic,
            PIN: pin,
            isGroupPin: isGroupPin,
            description: description,
            welcomeMessage: welcomeMessage ? (welcomeMessage.trim().length === 0 ? undefined : welcomeMessage) : undefined,
            timeSlotStart: timeSlotStart,
            timeSlotEnd: timeSlotEnd,
            bayNumber: isGroupPin ? undefined : (bayNumbers.length === 1 ? bayNumbers[0] : undefined),
            bayNumbers: bayNumbers.length === 0 ? undefined : bayNumbers,
        };

        if (playerEmail !== null && playerFirstname !== null) {
            data.player = {
                firstName: playerFirstname,
                lastname: playerLastname,
                email: playerEmail
            }
        } else {
            data.player = undefined;
        }

        const BOOKINGS_BASE_URL = 'https://dev-api.inrangegolf.com/bookings/v1';
        axios
            .post(`${BOOKINGS_BASE_URL}/ranges/${selectedFacility.uuid}/bookings`, data)
            .then((res) => {
                // setSnackBarMessage("Bay PIN Created!");
                // setSnackBarOpen(true);
                setPin(res.data.pin);
                setLinkPin(res.data);
                setLoading(false);
            })
            .catch((error: AxiosError) => {
                dispatch({
                    type: PINERROR,
                    payload: (error.response !== undefined)
                        ? error.response.data?.message
                        : error.message
                });
            });
    };

    useEffect(() => {
        if (show) {
            if (usePinTimeSlot) {
                createPin(+minutes, mode, newPin, description, timeSlotStart, timeSlotEnd, playerFirstname, playerLastname, playerEmail);
            } else {
                createPin(+minutes, mode, newPin, description, null, null, null, null, null);
            }

        }
    }, [show, minutes, mode, newPin, description, timeSlotStart, timeSlotEnd]);

    return (
        <MuiThemeProvider theme={theme}>
            <React.Fragment>
                {error === undefined && loading && (
                    <div className="loading">
                        <Typography color="primary">Creating Booking...</Typography>
                        <CircularProgress color="primary"/>
                    </div>
                )}
                {pin !== "" && (
                    <div className="TextField-with-border-radius">
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <FormControl variant="outlined" className={classes.formControl} fullWidth={true}>
                                    <TextField
                                        id="pin"
                                        label="PIN"
                                        type="text"
                                        color="primary"
                                        value={pin}
                                        InputProps={{readOnly: true}}
                                        variant="outlined"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl variant="outlined" className={classes.formControl} fullWidth={true}>
                                    <TextField
                                        id="minutes"
                                        label="Minutes"
                                        type="text"
                                        color="primary"
                                        value={minutes}
                                        InputProps={{readOnly: true}}
                                        variant="outlined"
                                    />
                                </FormControl>
                            </Grid>
                            {/*{ linkPin && usePinTimeSlot && !isStatic && !isGroupPin && (*/}
                            {/*    <Grid item xs={12} justify="center" alignItems="center">*/}
                            {/*        <FormControl variant="outlined" className={classes.formControl}>*/}
                            {/*            <Button*/}
                            {/*                variant="text"*/}
                            {/*                size="small"*/}
                            {/*                onClick={() => {*/}
                            {/*                    handleLinkPin()*/}
                            {/*                }}>*/}
                            {/*                { (bay === undefined ? 'Link Booking to Bay' : `Bay ` + bay.name ) } <LinkIcon />*/}
                            {/*            </Button>*/}
                            {/*        </FormControl>*/}
                            {/*    </Grid>*/}
                            {/*)}*/}
                            <Grid item xs={12}>
                                <FormControl variant="outlined" className={classes.formControl} fullWidth={true}>
                                    <Button variant="contained"
                                            color="primary"
                                            fullWidth={true}
                                            onClick={handleClose}>
                                        DONE
                                    </Button>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </div>
                )}
                {error !== undefined && (
                    <div className="TextField-with-border-radius">
                        <Typography variant="h3" color="error">
                            {error?.message}
                        </Typography>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <Button variant="outlined"
                                    size="small"
                                    onClick={handleRetryCreatePinOptions}>
                                Retry
                            </Button>
                        </FormControl>
                    </div>
                )}
            </React.Fragment>
            {linkPin == null ? "" : <LinkPin pin={linkPin!}
                                             show={showLinkPin}
                                             selectedFacility={selectedFacility}
                                             handleSubmit={handleLinkPinClose}
                                             handleClose={handleLinkPinClose}
            >
            </LinkPin>}
            <Snackbar
                open={snackBarOpen}
                autoHideDuration={6000}
                onClose={handleSnackBarClose}
            >
                <Alert onClose={handleSnackBarClose} severity="success">
                    {snackBarMessage}
                </Alert>
            </Snackbar>
        </MuiThemeProvider>
    );
};
